function MoonFace(rotationClass) {
  return (
    <svg
      id={rotationClass + "-moon-face"}
      className="moon-face"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 360 360"
      transform="scale(-.92849, .92849) translate(50, 0)"
      aria-label="Self portrait of the author of this site."
    >
      <circle
        className="bg"
        cx="-247.23"
        cy="-418.93"
        r="131.23"
        fill="var(--umbra)"
        transform="rotate(145.647 -118.832 -65.965) scale(.92849)"
        paintOrder="stroke markers fill"
      ></circle>
      <g transform="translate(-9, 0)">
        <g stroke="#31241c">
          <path
            fill="#3d2d23"
            d="M235.35 168.75c0 18.171-2.506 17.234-20.84 26.517-19.646 9.948-55.817 29.465-75.289 29.465-37.643 0-49.921-21.552-49.921-59.194 0-19.354 26.398-90.001 39.353-102.41 12.242-11.724 20.718-1.917 39.006-1.917 16.027 0 23.039-17.742 34.677-8.483 15.696 12.487 33.014 94.405 33.014 116.02z"
            paintOrder="stroke markers fill"
          ></path>
          <g fill="#573d2c">
            <path
              strokeWidth="3"
              d="M217.42 163.5c-1.61 2.11-7.163-36.584-8.627-35.064-23.9 24.802-25.318 15.33-45.112 13.902-37.474-1.349-52.814 7.3-53.054-28.417-.13-19.354-5.75-43.634 7.205-56.041 12.242-11.724 37.41-22.318 55.698-22.318 16.027 0 38.313 8.318 49.514 18.1 6.087 5.316 16.945 23.328 18.618 44.357 2.74 34.439-8.24 74.886-24.242 65.481z"
              paintOrder="stroke markers fill"
            ></path>
            <path
              strokeWidth="1.4"
              d="M166.47 57.2c1.736-.47 4.29-.89 7.385-.825 2.456.051 5.446.4 9.427 1.383 3.557.878 7.957 2.296 11.795 4.693 1.834 1.146 3.5 2.486 4.907 4.054a17.926 17.926 0 013.288 5.228c.804 1.967 1.421 4.37 1.87 7.183.449 2.804.71 5.878.856 9.122.148 3.266.176 6.593.185 9.894.008 3.266-.005 6.676.079 9.684.183 6.519.773 11.638 1.623 16.424.882 4.97 2.134 9.956 3.206 14.114 1.426 5.535 2.87 11.013 3.945 15.267.216.855.428 1.699.622 2.456.793 3.11 2.393 7.387 3.685 11.531 1.173 3.764 2.02 7.515 2.387 11.212.166 1.667.225 3.238.183 4.684-.04 1.43-.179 2.736-.406 3.87-.235 1.174-.622 2.24-1.242 3.256-.662 1.083-1.541 2.025-2.578 2.843-2.61 2.061-5.998 3.169-7.901 3.35 2.056-.196 5.073.527 9.083.652 1.532.048 3.287-.026 5.17-.458a17.474 17.474 0 005.61-2.388c1.863-1.205 3.631-2.79 5.246-4.694 1.622-1.913 3.069-4.125 4.304-6.562 2.698-5.326 4.243-11.436 4.68-17.564.476-6.705-.315-12.89-1.263-16.607a77.91 77.91 0 00-.832-3c-1.558-5.23-3.79-10.91-6.362-16.53-1.838-4.013-3.873-7.893-5.71-11.906-1.554-3.386-3.075-7.156-4.364-12.367-.634-2.563-1.212-5.567-1.86-8.948-.628-3.271-1.315-6.86-2.145-10.42-.827-3.543-1.818-7.152-3.09-10.556-1.272-3.4-2.86-6.692-4.919-9.536a27.706 27.706 0 00-7.093-6.824c-2.594-1.732-5.305-2.934-7.91-3.769-5.406-1.733-10.75-2.014-14.602-1.887-4.437.147-7.647.85-10.085 1.56-3.204.935-5.359 1.891-7.174 2.382z"
            ></path>
          </g>
        </g>
        <path
          fill="#434358"
          stroke="#434358"
          strokeWidth="3"
          d="M136.71 185.95c-12.014.743-14.848 4.05-28.911 7.543-14.063 3.494-39.35 7.174-49.541 12.408-10.191 5.235-5.27 12.017.13 18.137 5.398 6.12 11.281 11.587 21.333 17.746s24.274 13.013 44.045 16.837c19.771 3.825 45.09 4.622 64.62 1.668s33.27-9.656 43.931-17.488 18.245-16.793 23.127-23.569 7.06-11.365 1.53-15.513c-5.53-4.148-18.769-7.852-31.034-10.256-12.265-2.405-23.554-3.508-39.79-4.971-16.238-1.463-37.425-3.284-49.439-2.54z"
        ></path>
        <path
          fill="#232333"
          stroke="#232333"
          strokeWidth="1"
          d="M123.36 199.58c-4.958-6.564 1.492-9.733 2.252-15.142.76-5.408-4.174-13.07-3.013-17.998 1.161-4.927 8.412-7.134 12.79-6.174s5.873 5.077 9.58 9.397 9.61 8.822 18.443 8.474 20.587-5.548 25.849-9.14c5.263-3.593 4.033-5.58 5.83-5.475 1.798.105 6.62 2.3 7.143 7.106s-3.25 12.218-2.794 17.172c.455 4.954 5.141 7.442-.552 13.402-5.694 5.96-21.771 15.398-37.996 15.14-16.225-.256-32.573-10.2-37.531-16.763z"
        ></path>
        <g fill="#573d2c" stroke="#31241c">
          <path
            strokeWidth="1.4"
            d="M171.38 49.613c-2.754.461-6.292.718-10.824 1.236-3.439.393-7.663.985-12.658 2.193-4.393 1.064-9.86 2.72-15.182 5.416-5.022 2.545-10.3 6.208-14.42 11.604-2.032 2.663-3.687 5.603-5.11 8.61-1.416 2.994-2.628 6.114-3.743 9.121-2.386 6.434-4.138 11.683-6.627 16.2-2.428 4.407-5.648 8.488-9.08 12.7-3.93 4.821-7.86 9.495-11.47 15.05-4.386 6.75-7.438 13.375-9.281 18.623a63.071 63.071 0 00-1 3.092c-.542 1.838-1.002 4.09-1.277 6.594-.313 2.853-.394 6.127-.021 9.474.643 5.776 2.627 10.792 5.575 14.69 2.658 3.514 5.676 5.572 8.09 6.685 2.58 1.19 4.68 1.641 6.368 1.838 2.275.266 3.534.099 4.287.617-.662-.456-1.22-1.833-1.557-3.773-.274-1.58-.363-3.142-.603-5.519-.23-2.282-.236-4.292.19-6.518.423-2.217 1.27-4.507 2.227-6.919a377.6 377.6 0 001.932-5.032c.564-1.507 1.1-2.997 1.478-4.282.202-.687.422-1.43.663-2.224 1.19-3.922 2.905-9.09 5.448-14.686 2.287-5.034 4.456-8.692 7.93-15.136 2.752-5.103 5.898-11.26 7.975-18.156 1.935-6.427 2.837-13.492 3.745-19.373.466-3.015.94-5.821 1.549-8.445.606-2.615 1.326-4.96 2.246-7.026 1.899-4.26 5.025-7.924 8.822-11.088 3.98-3.317 8.476-5.9 12.4-7.89 4.412-2.239 8.19-3.78 11.45-4.908 4.138-1.432 7.82-2.323 10.477-2.768z"
          ></path>
          <path
            strokeWidth="1.8"
            d="M200.23 69.983c-3.254-1.922-7.147-4.697-12.653-7.656-4.184-2.248-9.585-4.681-16.881-6.456-6.414-1.56-14.913-2.762-23.61-1.916-4.165.405-8.492 1.292-12.689 2.92-4.13 1.602-8.18 3.938-11.803 7.272-3.582 3.297-6.552 7.381-9.046 11.764-2.492 4.38-4.551 9.135-6.294 13.851-1.754 4.75-3.212 9.528-4.467 13.911-1.278 4.466-2.36 8.565-3.333 11.884-2.048 6.994-3.695 11.108-5.314 14.298-.849 1.672-1.711 3.129-2.588 4.535-.906 1.452-2.027 3.16-3.112 4.836a175.353 175.353 0 00-4.018 6.472c-1.608 2.74-3.05 5.445-4.27 8.131-.453 1-.866 1.976-1.239 2.929-.69 1.764-1.384 4.082-1.884 6.772-.566 3.048-.938 6.89-.56 11.016.649 7.074 3.535 13.5 7.63 18.481 1.868 2.271 3.951 4.198 6.109 5.711 2.14 1.5 4.382 2.613 6.579 3.232 2.302.649 4.39.683 6.212.31 1.91-.392 3.393-1.191 4.587-2.027 1.284-.9 2.427-2 3.293-2.829 1.22-1.167 2.161-2.059 3.123-2.645-.891.543-2.263 1.078-3.791 1.372-1.197.23-2.53.312-3.744.082-1.105-.21-2.007-.654-2.694-1.331-.63-.622-1.026-1.375-1.434-2.318-.41-.948-.881-2.074-1.322-3.492a25.998 25.998 0 01-.988-4.558c-.445-3.656.03-6.7 1.027-9.146.611-1.497 1.264-2.913 2.038-4.582.637-1.371 1.264-2.723 1.732-3.92.251-.643.518-1.32.817-2.066a296.157 296.157 0 012.86-6.851c.866-2.024 2.028-4.707 2.953-7.01a90.672 90.672 0 002.468-6.874 76.276 76.276 0 001.845-7.12c1.012-4.825 1.679-10.053 2.55-17.632.419-3.642.86-7.66 1.469-12.018.595-4.26 1.335-8.722 2.316-13.1.976-4.354 2.174-8.547 3.668-12.33 1.498-3.79 3.25-7.055 5.29-9.647 2.059-2.612 4.593-4.744 7.52-6.46 2.988-1.753 6.328-3.037 9.842-3.937 7.398-1.895 15.221-2.006 21.548-1.507 7.092.56 12.548 1.903 16.974 3.432 5.518 1.905 10.245 4.423 13.285 6.217z"
          ></path>
        </g>
        <path
          fill="#232333"
          stroke="#232333"
          strokeWidth="3"
          d="M213.04 130.86c0 37.643-23.862-53.721-52.116-53.721s-47.82 47.945-47.82 10.302c0-13.653 11.422-30.264 19.156-33.144 12.722-4.739 25.256-6.361 39.118-4.688 12.686 1.53 25.518 2.55 36.61 9.66 6.363 4.077 13.081 17.514 13.533 27.31.156 3.375-8.48 40.17-8.48 44.28z"
          paintOrder="stroke markers fill"
        ></path>
        <path
          fill="#dadaf3"
          d="M186.98 121.29c-.149 1.264-.868 2.261-2.057 3.097-2.077 1.46-4.816 2.748-8.505 2.231-5.801-.812-10.172-4.286-9.763-7.76.409-3.472 5.443-5.629 11.244-4.816 5.8.813 9.49 3.775 9.08 7.248z"
          paintOrder="stroke markers fill"
        ></path>
        <g
          strokeWidth="1.227"
          transform="matrix(.7802 0 0 .85098 35.979 21.04)"
        >
          <circle
            cx="195.15"
            cy="85.064"
            r="8.59"
            fill="#344337"
            paintOrder="stroke markers fill"
            transform="rotate(9.027)"
          ></circle>
          <circle
            cx="195.15"
            cy="85.064"
            r="4.326"
            paintOrder="stroke markers fill"
            transform="rotate(9.027)"
          ></circle>
          <path
            fill="#dadaf3"
            d="M176.06 112.92c-.809-.252-1.756-.158-2.275.601-.519.76-.606 2.18-.18 2.748.425.567 1.361.282 2.214-.179.853-.46 1.621-1.094 1.67-1.708.05-.615-.62-1.21-1.429-1.462z"
          ></path>
        </g>
        <path
          fill="#f6d3bd"
          stroke="#120b07"
          d="M146.86 57.828c-7.956.617-14.998 9.148-16.275 14.505-1.067 4.477-7.144 17.916-7.315 22.572-.106 2.887-4.816 10.032-4.68 12.918.081 1.69-.736 6.979-1.258 10.432-.788 5.203-1.437 8.738-1.557 9.415-.746 4.181 2.081 11.867 3.156 15.994 6.479 19.256 12.294 26.396 27.492 38.516 2.202 1.756 5.12 2.764 10.634 3.238 7.313.629 15.334-4.5 23.216-8.946 10.933-6.166 17.931-15.192 20.031-23.526 3.871-15.36 5.524-38.796 5.846-40.688.61-3.579-8.925-26.63-8.733-30.278.276-5.25-14.202-19.964-18.144-21.693-5.08-2.227-31.46-2.533-32.413-2.46z"
          paintOrder="stroke markers fill"
        ></path>
        <g fill="#775e4d">
          <path d="M171.67 130.21c-.334.633.194 2.087.875 2.58.681.493 1.516.026 1.849-.606.333-.631.166-1.428-.515-1.922-.68-.494-1.875-.685-2.209-.052z"></path>
          <path d="M127.44 141.92c.376.147 1.124.44 1.483.193.359-.245.327-1.03-.07-1.427-.397-.398-1.16-.409-1.52-.162-.359.246-.314.748-.291.999.022.25.022.25.398.397zM121.29 141.97c.375.324 1.465.358 1.635.051.17-.306-.579-.953-1.124-.97-.545-.018-.886.595-.511.919zM193.67 130.44c-.228.487-.263 1.386-.03 1.67.234.285.737-.044.965-.53.228-.487.18-1.132-.053-1.417-.233-.286-.654-.21-.882.277zM124.47 136.8c-.353.051-1.057.154-1.405.589-.348.434-.34 1.197.196 1.487.537.29 1.603.108 1.952-.325.35-.433-.02-1.117-.205-1.46-.185-.343-.185-.343-.538-.291zM192.89 124.93c.217.592 1.315.704 1.775.497s.281-.73.064-1.324c-.216-.593-.47-1.251-.93-1.046-.458.206-1.126 1.281-.909 1.873zM174.24 144.47c.69.539 2.618.505 2.893-.05.274-.556-1.105-1.635-2.07-1.618-.964.016-1.513 1.129-.823 1.668zM131.9 133.89c-.61-.335-1.234-.704-1.591-.177-.358.527-.446 1.947-.02 2.515.426.567 1.362.282 2.015-.095.653-.376 1.022-.843.91-1.226-.111-.383-.704-.682-1.314-1.017zM184.9 130.19c.267-.532 2.22-.525 2.928.012.708.536.173 1.6-.802 1.596-.976-.004-2.393-1.076-2.126-1.608z"></path>
          <path d="M181.54 145.79c-.436.65.438 1.756 1.312 1.66.873-.096 1.746-1.393 1.31-1.947-.437-.553-2.186-.362-2.622.287z"></path>
          <path d="M192.18 142.65c-.435-.31-1.728-.6-2.235-.437-.507.163-.228.78.203 1.27.432.49 1.016.854 1.311.856.295.002.3-.358.516-.704.215-.345.64-.675.205-.985z"></path>
          <path d="M139.4 131.23c-.857-.41-1.715-.821-2.15-.591s-.452 1.102.022 1.646c.474.544 1.436.76 1.873.53.436-.23.345-.908.255-1.585z"></path>
          <path d="M118.29 127.09c.47.75 2.314 1.383 2.766.95s-.486-1.935-1.409-2.251c-.922-.317-1.827.55-1.357 1.301z"></path>
          <path d="M119.88 133.63c-.11.417.384 1.103 1.062 1.204s1.54-.379 1.894-.8c.354-.421.2-.783-.122-.96-.322-.176-.811-.164-1.412-.085-.6.078-1.313.224-1.422.641z"></path>
          <path d="M124.86 130.92c.114.309.536.48 1.063.514.527.033 1.155-.07 1.285-.422.13-.351-.24-.95-.668-1.208-.429-.257-.915-.173-1.257.093-.341.266-.537.714-.423 1.023zM170.67 136.88c-.527.067-1.58.2-1.938-.045-.359-.246-.024-.87.524-1.189.55-.318 1.313-.329 1.672-.082.36.246.314.747.292.998-.023.251-.023.251-.55.318zM138.53 136.85c.429.318 1.103 1.46 1.103 1.993 0 .532-.672.456-1.271.196-.6-.26-1.125-.704-1.218-.984-.093-.28.249-.396.511-.707.263-.31.446-.816.875-.498zM179.09 138.51c-.522.467-2.063.541-2.311.112-.248-.429.796-1.362 1.566-1.4.77-.037 1.267.821.745 1.288z"></path>
          <path d="M167.76 127.83c-.03.35-.58.687-1.086.56-.507-.129-.972-.721-1.11-1.24s.056-.961.319-1.014c.262-.054.595.285 1.008.636s.9.709.87 1.058z"></path>
          <path d="M197.88 138.94c-.565.28-1.617.348-1.957.082-.34-.265.03-.864.593-1.146.564-.281 1.322-.245 1.663.021.341.267.265.762-.299 1.043z"></path>
          <path d="M186.12 135.66c-.412.678.526 1.758 1.41 2.055.882.296 1.71-.191 2.123-.868s.407-1.546-.476-1.843c-.883-.297-2.645-.022-3.057.656z"></path>
          <path d="M142.95 125.65c-.458.433-.128 1.486.243 1.828.371.341.782-.03 1.242-.464.46-.433.965-.926.595-1.266-.37-.341-1.622-.531-2.08-.098zM133.29 139.74c-.13.331-.392.994-.189 1.512s.87.89 1.388.57.891-1.337.69-1.855c-.202-.518-.98-.538-1.369-.548-.39-.01-.39-.01-.52.321z"></path>
        </g>
        <path
          fill="#2b1f1a"
          d="M149.059 110.113c.286-.128 1.435 1.31 2.575 4.356a20.033 20.033 0 011.233 7.68 25.1 25.1 0 01-2.837 10.672c-1.58 3.016-4.117 5.336-4.931 8.02a1.442 1.442 0 00-.125.404c-.136.871.417 1.951 1.16 2.632.776.74 2.091 1.242 3.427 2.13 1.674 1.047 2.636 2.255 3.596 2.4.067.068.272.012.47-.074.306-.146.686-.499 1.176-.872a9.535 9.535 0 014.292-1.88c2.294-.402 4.006-.106 5.123-.664.677-.075.813-1.274.693-1.894-.337-1.708-1.521-2.678-1.343-2.862.177-.184 1.547.072 2.683 2.37.493 1.029.603 2.92-1.088 4.126-1.985 1.135-3.535.95-5.578 1.413a7.038 7.038 0 00-2.994 1.568c-.351.318-.82.773-1.487 1.201-.71.45-1.62.654-2.612.48-2.156-.569-3.436-1.844-4.643-2.657-1.001-.619-2.42-1.165-3.844-2.335-1.518-1.284-2.447-3.298-2.217-5.466.038-.355.106-.71.207-1.066 1.347-3.738 3.95-6.09 5.303-8.462 1.792-3.157 2.688-6.456 3.032-9.375.323-2.706.027-5.165-.342-7.016-.585-2.95-1.216-4.701-.93-4.83z"
          className="UnoptimicedTransforms"
        ></path>
        <path
          fill="#3d2d23"
          strokeWidth="1"
          d="M142.77 102.93c.623.08 1.279.264 1.915.676.71.533 1.009 1.486.762 2.448-.296.846-.867 1.435-1.504 1.72-1.784.74-3.562.067-4.762-.154-2.637-.38-5.493-.726-7.54-.805-2.242-.876-7.91-1.172-9.494 1.303-2.499 2.465-3.073 5.203-3.248 5.119-.175-.085-.09-2.78 2.556-5.944 1.512-1.737 3.655-2.701 6.371-3.214 3.154-.637 5.645-.737 9.455-1.069a37.573 37.573 0 013.218-.17c.4-.001.8.007 1.198.026.338.003.7.015 1.073.064z"
          paintOrder="stroke markers fill"
        ></path>
        <path
          fill="#fff"
          d="M143.69 120.49c-.156 1.203-1.4 3.019-2.547 3.8-2.003 1.366-4.637 2.56-8.162 2.023-4.141-.632-8.04-3.048-9.569-5.608-.517-.866-.763-1.748-.655-2.584.427-3.305 6.23-4.623 11.772-3.777 5.543.846 9.588 2.84 9.161 6.146z"
          paintOrder="stroke markers fill"
        ></path>
        <ellipse
          cx="146.13"
          cy="101.29"
          fill="#3a3c31"
          paintOrder="stroke markers fill"
          rx="4.746"
          ry="5.145"
          transform="matrix(.9924 .12308 -.1045 .99452 0 0)"
        ></ellipse>
        <ellipse
          className="pupil"
          fill="#18181bff"
          cx="146.13"
          cy="101.29"
          paintOrder="stroke markers fill"
          rx="2.39"
          ry="2.591"
          transform="matrix(.9924 .12308 -.1045 .99452 0 0)"
        ></ellipse>
        <path
          fill="#fff"
          d="M132.36 117.61c-.26.04-.78.121-1.047.59-.268.469-.282 1.322-.033 1.65.248.329.758.133 1.217-.165.459-.298.867-.698.88-1.067.012-.37-.372-.709-.565-.878-.193-.17-.193-.17-.452-.13z"
        ></path>
        <path
          fill="#f6d3bd"
          d="M146.051 118.973c-.842-.638-1.877.967-3.987 2.077-.964.566-1.82.784-3.435 1.399-1.268.423-2.584.907-3.632.9a7.816 7.816 0 01-1.164-.018c-.982-.083-2.056-.321-3.094-.562-1.404-.368-2.492-.702-3.456-1.212-1.044-.56-1.796-1.096-2.42-1.712-1.123-.973-1.84-2.336-2.428-2.174-.587.161-1.185 1.428-.326 3.994.466 1.315 1.351 2.727 2.702 3.904 1.295 1.128 2.834 1.984 4.528 2.569 1.204.449 2.591.814 4.115.926a12.9 12.9 0 001.88.003c2.144-.232 3.884-1.03 5.186-1.798 1.526-.833 2.887-1.81 3.975-3.1 2.187-2.768 2.16-4.739 1.556-5.196z"
          className="UnoptimicedTransforms"
        ></path>
        <path
          fill="#2b1f1a"
          d="M122.399 117.687c-.16-.34.767-1.164 2.143-2.4.613-.548 1.554-1.38 2.68-2.002a14.277 14.277 0 015.307-1.641c.494-.061 1.008-.1 1.535-.11 1.729-.028 3.382.28 4.874.99 1.705.891 2.783 2.322 3.455 3.317.544.805 1.125 1.523 1.516 2.149.627.984.727 1.64.53 1.804-.225.189-.692-.209-1.492-.741-.471-.326-1.34-.938-2.09-1.656-.842-.77-1.918-1.867-2.929-2.183-1.102-.439-2.36-.58-3.82-.556-.444.008-.89.03-1.328.058a17.302 17.302 0 00-4.601 1.01c-.811.283-1.98.937-2.673 1.27-1.8.879-2.947 1.031-3.107.69z"
          className="UnoptimicedTransforms"
        ></path>
        <path
          fill="#3d2d23"
          d="M168.58 102.5c.278.007.547.026.804.047.455.02.91.053 1.365.096 1.25.116 2.475.303 3.647.502 4.305.723 6.539.712 10.161 1.107 3.119.304 5.603 1.105 7.402 2.727 3.156 2.961 3.371 5.65 3.175 5.747-.195.098-.97-2.595-3.927-4.869-1.628-1.312-6.013-1.471-11.021-.957-2.329.237-5.372.53-8.411.636-1.388.096-3.479.584-5.436-.337a3.013 3.013 0 01-1.54-1.869c-.186-.982.25-1.899 1.11-2.356.918-.413 1.836-.495 2.671-.474z"
          paintOrder="stroke markers fill"
        ></path>
        <path
          fill="#fff"
          d="M188.16 118.32c-.196 1.257-2.227 5.621-3.446 6.404-2.129 1.367-4.914 2.533-8.58 1.854-5.767-1.068-10.005-4.733-9.468-8.185.538-3.452 5.648-5.384 11.414-4.316 5.766 1.068 10.617.791 10.08 4.243z"
          paintOrder="stroke markers fill"
        ></path>
        <g
          strokeWidth="1.227"
          transform="matrix(.77956 .03445 -.03159 .85028 78.034 16.843)"
        >
          <circle
            cx="139.73"
            cy="102.03"
            r="6.35"
            fill="#3a3c31"
            paintOrder="stroke markers fill"
            transform="rotate(5.443)"
          ></circle>
          <circle
            className="pupil"
            cx="139.73"
            cy="102.03"
            r="3.198"
            fill="#18181bff"
            paintOrder="stroke markers fill"
            transform="rotate(5.443)"
          ></circle>
          <path
            fill="#fff"
            d="M126.62 113.51c-.346.056-1.04.17-1.389.753-.347.585-.346 1.637-.005 2.035.34.4 1.017.146 1.625-.234.608-.379 1.145-.882 1.154-1.337.008-.456-.514-.864-.776-1.069s-.262-.204-.609-.148z"
          ></path>
        </g>
        <path
          fill="#f6d3bd"
          d="M189.662 118.884c-.927-1.111-3.712 1.154-7.63 3.44-1.214.685-2.609 1.285-3.964 1.364l-.206.015c-1.586.108-3.029.005-4.32-.31-1.16-.23-2.314-.88-3.25-1.476-1.983-1.29-3.276-2.409-3.975-1.818-.51.43-.375 2.367 1.26 4.866a10.328 10.328 0 004.357 3.722 13.01 13.01 0 006.604 1.02c2.582-.286 4.75-1.45 6.33-2.843 2.009-1.79 3.394-3.734 4.14-5.087.775-1.408 1.025-2.45.654-2.893z"
          className="UnoptimicedTransforms"
        ></path>
        <path
          fill="#2b1f1a"
          d="M189.976 118.576c.195-.505-1.318-1.697-3.751-3.322-1.529-1.024-3.899-2.526-6.57-3.39a15.203 15.203 0 00-2.687-.61 13.259 13.259 0 00-4.532.163c-1.931.378-3.6 1.4-4.733 2.837-.98 1.366-1.3 2.822-1.46 3.677-.288 1.533-.51 2.493-.17 2.694.306.18 1.042-.425 1.904-1.903.441-.72 1.06-1.942 1.685-2.426.766-.73 1.884-1.121 3.32-1.365 1.115-.163 2.304-.151 3.57.021.759.104 1.508.26 2.229.448 2 .519 4.855 1.79 6.366 2.354 2.78 1.05 4.633 1.326 4.829.822zM173.95 155.858c.051-.032.317.278.716.817.373.503.962 1.348 1.273 2.29.09.277.156.551.194.826.095.87-.334 1.58-.632 1.972-.318.418-.563.61-.603.577-.04-.033.13-.292.372-.728.227-.408.576-1.082.446-1.76a4.587 4.587 0 00-.183-.753c-.276-.834-.832-1.738-1.127-2.255-.33-.58-.506-.954-.455-.986z"
          className="UnoptimicedTransforms"
        ></path>
        <path
          fill="#c27c76"
          d="M165.91 165.97l-5.814 2.152c-3.79 1.402-7.863.463-8.905.09-.778-.28-2.822-.76-3.359-1.36-.528-.59-1.205-.628-1.742-1.266-.512-.607-5.324-6.256-4.88-6.715.654-.02 6.263-1.325 7.063-1.447 1.376-.21 2.815-.268 3.534 0 .815.303 1.996.735 3.198.677 1.59-.077 3.274-.708 4.745-.624 2.938.17 3.078-.153 5.45.793 1.266.506 7.128 1.165 7.113 1.183-.886.996-.265.907-.893 1.504-1.477 1.404-3.924 3.678-5.51 5.013z"
          paintOrder="stroke markers fill"
        ></path>
        <g fill="#2b1f1a">
          <path
            d="M171.891 160.133c.09.062-.524 1.37-2.296 3.095-1.14 1.106-2.571 2.185-4.493 3.323-1.661.991-3.755 2.068-6.06 2.52-1.236.234-2.47.259-3.734.193-.769-.04-1.535-.113-2.258-.181-2.285-.197-4.32-.742-5.882-1.98-2.203-1.793-3.834-4.23-4.687-5.543-.962-1.482-1.465-2.364-1.381-2.427.083-.063.75.71 1.84 2.087.938 1.184 2.739 3.562 4.836 5.148 1.388 1.076 3.25 1.507 5.446 1.702.725.06 1.488.128 2.225.169 1.22.067 2.366.055 3.484-.155 2.106-.378 4.128-1.373 5.778-2.28 1.92-1.062 3.316-2.01 4.493-3.008 1.783-1.506 2.598-2.725 2.689-2.663zM140.68 158.59c.019-.075 1.206.135 3.177-.314 1.113-.254 4.357-1.252 6.49-1.324 1.515-.034 2.926.684 4.404.71.603.015 1.22-.04 1.864-.122 1.42-.183 2.694-.445 4.074-.376 2.256.125 5.377 1.166 6.768 1.488 2.954.686 4.99.483 4.985.58-.005.098-2.028.485-5.126-.096-1.905-.358-4.136-1.214-6.712-1.316-1.274-.059-2.496.209-3.957.393-.662.083-1.314.138-1.969.122-1.694-.057-3.052-.789-4.369-.761-2.5.023-4.722.813-6.403 1.1-2.053.354-3.244-.009-3.226-.084z"
            className="UnoptimicedTransforms"
          ></path>
          <path
            d="M144.05 160.047c-.035.046.657.809 2.267 1.271 1.174.336 2.767.523 4.498.904 1.022.228 2.387.742 3.692.73.124-.002.248-.008.372-.018 1.325-.107 2.537-.58 3.653-.767 4.512-.753 7.826-.795 7.828-.896.002-.101-3.284-.234-7.863.466-1.18.18-2.392.647-3.617.742a5.11 5.11 0 01-.343.015c-1.174.025-2.528-.475-3.6-.69-1.777-.363-3.354-.498-4.523-.771-1.563-.362-2.328-1.032-2.364-.986z"
            className="UnoptimicedTransforms"
          ></path>
        </g>
        <path
          fill="#573d2c"
          stroke="#31241c"
          strokeWidth="1"
          d="M155.15 57.332c1.507.233 3.043.692 5.305 1.314 1.682.463 3.79 1.019 6.365 1.465 2.294.399 4.946.728 7.788 1.49 2.655.711 5.548 1.814 8.775 3.788 3.167 1.936 6.605 4.681 9.398 8.2 1.369 1.724 2.545 3.589 3.461 5.583a23.464 23.464 0 011.896 6.308c.343 2.262.43 4.858.336 7.81-.096 2.988-.371 6.131-.686 9.478-.312 3.321-.67 6.906-.853 10.353-.187 3.534-.214 7.278.25 10.919.492 3.852 1.46 7.27 2.647 10.324 1.223 3.152 2.68 5.915 4.012 8.237 1.426 2.488 2.75 4.572 3.811 6.356 1.18 1.982 1.962 3.447 2.536 4.793 1.35 3.171 1.905 6.089 2.195 8.337.022.168.042.334.061.496.216 1.829 1.032 4.341 1.273 7.484.199 2.601-.226 5.86-1.63 9.625-1.284 3.446-3.182 6.739-5.264 9.466-2.2 2.884-4.561 4.98-7.133 6.623-3.28 2.096-6.715 3.313-8.808 3.586 2.206-.288 5.475.211 9.835.332 3.242.09 7.241-.104 11.847-1.607 4.335-1.415 8.996-3.89 13.246-7.337 4.58-3.715 8.503-8.415 11.05-13.857 3.183-6.801 3.714-13.344 3.213-17.581a39.142 39.142 0 00-.153-1.15c-.768-5.182-2.735-11.354-6.608-17.455-1.59-2.504-3.345-4.713-5.034-6.698-1.4-1.647-3.005-3.414-4.406-5.06-1.26-1.478-2.38-2.888-3.323-4.339-.89-1.371-1.686-2.88-2.274-4.648-.599-1.803-1.028-4.185-1.339-7.195-.305-2.957-.461-6.102-.662-9.679-.19-3.4-.419-7.117-.882-10.756-.46-3.607-1.174-7.354-2.415-10.911a36.672 36.672 0 00-4.966-9.483 39.24 39.24 0 00-6.614-7.05c-4.74-3.96-9.929-6.491-14.36-7.883-4.656-1.463-8.62-1.715-11.867-1.546-3.34.174-6.337.842-8.36 1.222-2.46.462-4.492.704-6.236.81-2.194.132-4.037.052-5.427-.163z"
        ></path>
        <g stroke="#31241c">
          <g strokeWidth="1">
            <path
              fill="#573d2c"
              d="M224.13 181.96c1.036-1.623 2.309-3.002 4.15-5.26 1.298-1.592 2.851-3.61 4.17-6.125 1.195-2.275 2.256-5.056 2.842-8.522.553-3.274.667-7.07.13-11.499-.524-4.325-1.635-9.11-3.153-14.099-1.542-5.07-3.499-10.328-5.664-15.486-4.86-11.575-10.652-22.387-15.52-30.153-3.676-5.866-6.938-10.219-9.497-13.066-2.12-2.36-5.151-4.972-8.656-7.414-5.723-3.99-11.775-6.904-17.234-8.724-4.965-1.655-8.921-2.36-12.214-2.771-4.215-.526-7.065-.618-8.338-1.51 1.312.92 2.4 3.55 4.008 7.34 1.257 2.96 2.8 6.503 5.28 10.865 2.469 4.337 6.132 10.132 9.153 14.557 2.005 2.936 4.024 5.709 5.764 7.644 2.163 2.407 5.37 5.554 9.488 9.956 5.631 6.02 12.574 14.07 19.611 23.512 3.061 4.107 5.958 8.241 8.52 12.2 2.531 3.91 4.633 7.492 6.236 10.622 1.612 3.147 2.707 5.857 3.412 8.268.752 2.569 1.078 4.857 1.09 7.014.015 2.427-.372 4.635-.936 6.593-.715 2.481-1.741 4.647-2.64 6.058z"
            ></path>
            <path
              fill="#694936"
              d="M159.16 64.671c-.81.46 2.866 11.696 14.319 25.94 6.572 8.14 15.883 16.483 24.339 26.422a268.69 268.69 0 013.515 4.212 256.735 256.735 0 0112.202 16.18c5.16 7.48 8.943 13.419 11.582 19.987 2.09 5.263 2.667 9.735 2.082 13.603-.925 6.739-3.558 10.668-3.064 10.945.495.277 3.953-2.682 6.066-10.31 1.155-4.377 1.044-9.77-.984-15.74-2.403-7.179-6.194-13.85-11.245-21.482a202.264 202.264 0 00-12.308-16.723 210.71 210.71 0 00-3.613-4.302c-8.945-10.353-18.376-18.27-25.126-25.735-11.6-12.76-16.955-23.458-17.765-22.998z"
              className="UnoptimicedTransforms"
            ></path>
          </g>
          <path
            fill="#573d2c"
            strokeWidth="1"
            d="M107.79 181.77c-1.993-1.27-4.85-3.42-7.013-6.073-.9-1.103-1.708-2.324-2.339-3.68a14.904 14.904 0 01-1.23-4.09c-.203-1.38-.243-2.98-.133-4.768.106-1.714.348-3.568.699-5.507.68-3.765 1.728-7.632 2.83-11.125 1.086-3.448 2.169-6.64 3.665-10.317 1.486-3.653 3.367-7.745 5.897-12.598 2.721-5.23 5.73-10.28 9.067-14.95 3.716-5.199 7.502-9.45 11.312-12.881 4.568-4.095 9.8-7.6 13.822-10.207 1.074-.697 2.08-1.34 3.001-1.935 2.376-1.534 5.923-5.496 8.66-9.236 2.791-3.8 4.628-7.234 6.38-10.287 2.117-3.69 3.987-6.452 5.418-6.816-1.403.356-4.219-1.33-8.788-2.658-3.784-1.1-8.365-1.748-13.86-1.295-5.596.468-11.398 2.162-14.62 4.24a84.86 84.86 0 00-3.739 2.564c-4.784 3.464-11.507 9.038-17.343 16.193-4.786 5.88-8.853 12.6-12.209 19.892-3.008 6.538-5.337 13.306-7.054 19.885-1.65 6.304-2.457 11.71-2.745 16.559-.283 4.764-.055 8.788.247 12.44.316 3.84.559 8.12 1.209 12.49.33 2.225.756 4.384 1.326 6.398.594 2.1 1.338 4.02 2.276 5.676a19.449 19.449 0 003.273 4.252c1.25 1.233 2.572 2.226 3.819 3.052 3.14 2.082 6.049 3.429 8.172 4.78z"
          ></path>
          <path
            fill="#694936"
            d="M164.11 56.206c.202-.24 1.375-.492 2.882.873.713.716 1.383 1.894 1.41 3.427.01 1.61-.403 3.185-1.035 4.65a24.822 24.822 0 01-2.204 4.287c-2.208 3.432-4.875 6.302-8.257 9.732-4.584 4.686-10.291 10.014-16.923 15.239-6.193 4.91-14.148 10.454-19.581 16.28a85.212 85.212 0 00-2.862 3.204c-5.665 6.676-9.817 13.718-12.273 20.885-3.046 8.629-3.481 17.878-3.319 25.177.26 6.033.575 9.571 2.015 13.084 1.856 4.97 4.534 7.978 3.827 8.724s-4.346-.21-8.718-5.854c-2.826-3.814-4.642-8.779-5.623-15.095-1.383-7.949-1.871-18.405 1.22-29.359 2.568-8.861 7.251-17.48 13.862-25.229a77.341 77.341 0 013.353-3.681c6.94-7.11 15.32-12.527 21.663-16.861 6.515-4.477 12.615-9.131 17.603-12.977 4-3.129 6.35-4.776 8.908-7.313a29.425 29.425 0 002.571-2.863c.869-1.237 1.387-1.718 1.687-2.484.25-.421.331-.928.277-1.344-.15-1.132-.805-2.12-.483-2.502z"
            className="UnoptimicedTransforms"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default MoonFace;
